@use '../../assets/scss/variables';

.monthYearPicker {
  position: absolute;
  z-index: 100;
  font-size: 10pt;
  color: black;
  padding: 10px;
  width: 350px;
  transition: all 500ms;
  border-radius: 10px;
  border: 1px solid #efefef;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .selectMonth {
    display: inline-block;
    width: 120px;
    min-width: 120px;
    padding: 5px;
    margin-right: 10px;
    font-size: 1.3em;
    vertical-align: top;
  }

  svg {
    margin-top: 2px;
    width: 24px;
    height: 24px;
  }

  .grid {
    position: relative;
    margin-top: 10px;

    .weekNum {
      width: 14%;
      display: inline-block;
      vertical-align: top;
      border-right: 1px solid #ccc;
      list-style: none;
      margin: 0 0 0 0;
      line-height: 1.2;
      padding: 0;

      li {
        padding: 10px;
        font-size: 1.15em;
        color: #ddd;
        text-align: center;
        font-weight: bold;

        &:first-child {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          padding: 10px 0 10px 0;
          margin-top: 13px;
          font-size: 0.9em;
        }
      }
    }

    .daysHalf {
      width: 85%;
    }

    .days {
      display: inline-block;
      color: black;

      .oneCell {
        display: inline-block;
        width: 14%;
        vertical-align: top;
        text-align: center;
        font-size: 1.2em;
        padding: 8px;
      }

      .header {
        padding: 5px 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        li {
          @extend .oneCell;

          color: #ddd;

          &:last-child {
            color: #c78f8f;
            font-weight: bold;
          }
        }
      }

      .oneDay {
        @extend .oneCell;

        cursor: pointer;
        border-radius: 50%;

        &:hover {
          background-color: #cee3f5;
          color: #666;
        }
      }
    }
  }

  .isNotCurrentMonth {
    color: #ccc;
  }

  .isCurrentDay {
    background-color: lightblue;
    color: white;
  }

  .isWeekDays {
    color: #c78f8f;
    font-weight: bold;
  }

  .yearMain {
    display: inline-block;
    width: 100px;
    font-size: 2em;
  }

  .padding {
    padding: 10px 0;
  }

  .increaseYear,
  .decreaseYear {
    display: inline-block;
    padding: 3px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #efefef;
    border-radius: 5px;
    vertical-align: top;
  }

  .hover {
    background-color: #efefef;
  }

  .activeMonth {
    vertical-align: top;
    width: 25%;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    font-size: 1.5em;
    cursor: pointer;
    color: red;
    font-weight: bold;

    &:hover {
      @extend .hover;
    }
  }

  .oneMonth {
    @extend .activeMonth;

    font-weight: normal;
    color: grey;
    line-height: 50px;
    text-align: center;
    font-size: 1.5em;

    &:hover {
      @extend .hover;
    }

    .unActiveMonth {
      color: #ddd;
      text-decoration: line-through;
      cursor: not-allowed;

      &:hover {
        background-color: white;
      }
    }
  }
}
