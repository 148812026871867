@use './assets/scss/reset';
@use './assets/scss/variables' as *;

@font-face {
  font-family: Geometria;
  font-style: normal;
  font-weight: 400;
  src: url("/src/client/assets/fonts/Geometria-Heavy.otf") format("woff");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url("/src/client/assets/fonts/Lato-Black.ttf") format("woff");
}

@font-face {
  font-family: InterBlack;
  font-style: normal;
  font-weight: 400;
  src: url("/src/client/assets/fonts/Inter-Black.ttf") format("woff");
}

@font-face {
  font-family: InterRegular;
  font-style: normal;
  font-weight: 400;
  src: url("/src/client/assets/fonts/Inter-Regular.ttf") format("woff");
}

* {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  outline: none;
}

input[type="submit"]::-moz-focus-inner {
  border: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 13pt;
  width: 100%;
  position: relative;
  background-color: white;
  height: 100% !important;
  color: #495057;
}

body {
  font-family: 'InterRegular', sans-serif;
  line-height: 1.4;
  letter-spacing: -0.5px;
  width: 100%;
  // transition: all 0.1s ease-in-out;
}

.body {
  position: relative;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-self: stretch;
  align-items: stretch;

  .main {
    display: flex;
    align-self: stretch;
    align-items: stretch;
    background-color: #f7f7f7;
    margin-bottom: -20px;
    padding-bottom: 20px;
    position: relative;
    flex-direction: column;
    flex: 1;
  }

  a,
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: $link !important;

    &:hover {
      text-decoration: underline;
      color: $link-hover !important;
    }
  }

  h1 {
    font-family: Lato, 'Helvetica', sans-serif;
    margin: 10px 0;
    color: black;
  }

  h2,
  h3 {
    font-family: Inter, 'Helvetica', sans-serif;
    margin: 10px 0;
    color: black;
  }

  h1 {
    font-size: 2.4em;
    letter-spacing: -1px;
    line-height: 1.2;
    margin: 20px 0;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2em;
    margin: 20px 0;
    line-height: 1.2;
    text-decoration: none;
  }

  h3 {
    font-size: 1.7em;
  }

  h4 {
    font-weight: bold;
    font-size: 1.4em;
  }

  /* --- General --- */

  .hidden {
    display: none !important;
  }

  button {
    cursor: pointer;
  }

  input[type=text],
  textarea {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 50px;
    box-shadow: $background-input-shadow;
    padding: 13px 25px;
    margin: 0 10px;
    font-size: 1.1em;
  }

  input[type=text]:focus,
  textarea:focus {
    box-shadow: 0 0 10px #1993f7;
    border: 1px solid #1993f7;
  }

  hr {
    margin: 10px 0;
  }

  select {
    padding: 8px 15px;
    cursor: pointer;
    min-width: 250px;
    border-radius: 40px;

    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #b5b5b5 !important;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" fill="black" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>') right center no-repeat;
    background-size: 30px;
    background-color: white;
    background-position-x: 95%;
  }

  p {
    margin: 5px 0;
    padding: 5px 0;
  }
}

.mainBody {
  display: block;
  position: relative;
  max-width: $max-width-section;
  margin: 0 auto;
  padding-top: $headerHeight;
}

.mainBodyFull {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
}

.mainBodyError {
  max-width: 100%;
  width: 100%;
}

.bodyLight {
  @extend .body;
}

.bodyDark {
  @extend .body;

  background-color: #1f1f1f !important;
  color: white !important;

  h1, h2, h3, h4, h5 {
    color: white !important;
  }

  .main {
    background-color: #1f1f1f !important;
  }
}

.defaultInput {
  padding: 8px 16px;
  margin: 15px 0;
  color: $input-text-primary-color;
  font-size: 17px;
  line-height: 1.5;
  background: white;
  border-radius: 100px !important;
  border: 1px solid #b5b5b5 !important;
  position: relative;
  box-shadow: $background-input-shadow;

  &:focus {
    border: 1px solid #1993f7 !important;
    box-shadow: $background-input-active-shadow;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &.hasError {
    border: 1px solid $input-error-border-color;
    background: $input-error-bg-color;

    &::placeholder {
      color: red;
    }
  }
}

.pagination {
  position: relative;
  padding: 10px;
  list-style: none;
  border-radius: 3px;

  li {
    display: inline-block;

    a,
    span {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }

    &.active {
      position: relative;

      a {
        z-index: 3;
        display: block;
        color: #fff;
        border-radius: 3px;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  h1 {
    font-size: 2em;
  }
}
