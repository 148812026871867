@use '../../app.module.scss';
// @import '../../assets/scss/variables.scss';
@use '../../assets/scss/variables.scss' as *;

.container {
  padding-top: calc($headerHeight);

  .padding50 {
    padding: 50px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .accountCart {
      width: 90% !important;
    }

    .currencyBlock {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }

    .manageStation {
      background-color: #d7eafa;
      width: 90% !important;
      margin: 20px 0;
      border-radius: 20px;
      vertical-align: top;
      padding: 15px 15px 5px 15px;
      display: grid;
      grid-template-columns: 60px auto 1fr;

      .count {
        font-size: 3.8em;
        line-height: 0.75;
        padding-right: 10px;
        color: $brand-color;
      }

      p {
        padding: 0 0 5px 0;
        margin: 0;
      }

      img {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 0 15px 15px 0;
      }
    }

    .stopButton {
      padding: 10px 25px !important;
      display: block;
      margin: 0 auto;
    }

    .transactions {
      padding: 10px;
      font-size: 0.9em;

      .visible {
        display: block;
      }

      .invisible {
        display: none;
      }

      .oneShortView {
        padding: 10px;
        cursor: pointer;
      }

      h2 {
        color: gray;

        &.activeCharge {
          color: green !important;
        }

        &.historyCharge {
          color: gray;
        }
      }

      ul {
        padding: 0;
        margin: 0;

        &.chargeHistory {
          margin-top: 15px;
        }

        li {
          list-style: none;
          margin-bottom: 20px;

          span {
            font-size: 1.5em;
          }

          .chargeLight {
            display: grid;
            grid-template-columns: 1fr 0.3fr;
            grid-gap: 10px;
            align-items: flex-start;

            .date {
              color: #a2a2a2;
              font-size: 0.9em;
            }

            span {
              font-size: 1.8em !important;
            }

            .merge {
              text-align: center;
              text-decoration: underline;
              grid-column: 2/3;
              font-size: 0.8em;
              margin-top: -16px;
              margin-left: 40px;
              cursor: pointer;
            }

            .summary {
              display: grid;
              grid-template-columns: 25px 1fr;
              // grid-row: 1 / 3;
            }

            .rightAlign {
              text-align: right;
            }

            .lignIcons {
              width: 15px;
              height: 15px;
              opacity: 0.3;

              fill {
                color: lightgray !important;
              }
            }
          }

          .charge {
            display: grid;
            grid-template-columns: 100px 1fr;
            position: relative;

            .chargeActive {
              padding-bottom: 5px;
              color: $greenColor;
            }

            .chargeIdle {
              padding-bottom: 5px;
              color: #77b9f2;
            }

            .date {
              color: gray;
              padding-bottom: 10px;
            }

            .icon {
              width: 100% !important;
              height: 100px;
              margin-top: -5px;
              margin-left: -10px;
            }

            .info {
              .historyItem {
                display: grid;
                grid-template-columns: 1fr 0.6fr;

                .showMore {
                  text-decoration: underline;
                  cursor: pointer;
                }

                div:last-child {
                  font-size: 0.8em;
                }
              }

              li {
                list-style: disc;
                padding: 0;
                margin: 0 0 0 0;
              }
            }
          }

          h4 {
            font-family: InterBlack;
            font-size: 1.2em;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .container {
    padding: 35px 15px 15px 15px;
    font-size: 0.9em;

    .grid {
      display: grid;
      grid-template-columns: 1fr;

      .transactions {
        padding: 10px;

        h2 {
          font-size: 1.6em;
        }

        .charge {
          grid-template-columns: 80px 1fr !important;
          align-self: left;

          .icon {
            width: 100% !important;
            height: 60px !important;
          }
        }
      }
    }
  }
}
