@use '../../app.module.scss';
// @import '../../assets/scss/variables.scss';
@use '../../assets/scss/variables.scss' as *;

$bg: #f5fafc;
$base: #ebf2fa;
$base-tint: #a7d0e0;
$stroke: #244356;
$orange: #fdc23e;
$blue: #449baa;
$red: #ff7761;

@keyframes chargeAnimation {
  0% { opacity: 0.3;}
  50% { opacity: 1;}
  100% { opacity: 0.8;}
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.Available {
  path {
    stroke: #9dcb80 !important;
    opacity: 0.8 !important;
  }
}

.SuspendedEV,
.SuspendedEVSE {
  path {
    stroke: #a9e3fc !important;
    opacity: 0.5 !important;
  }
}

.Preparing {
  path {
    stroke: #06a9ef !important;
    animation: chargeAnimation 2s infinite !important;
  }
}

.Charging {
  path {
    stroke: #06a9ef !important;
    animation: chargeAnimation 2s infinite !important;
  }
}

.section {
  padding-top: calc($headerHeight + 10px);

  .content {
    margin: 0 auto;
    display: block;
    max-width: $max-width-section;
    padding: 2%;

    .top {
      display: grid;
      grid-template-columns: 80px 0.5fr 1fr;

      div:nth-child(3) {
        text-align: right;
      }

      h2 {
        margin: 0;
        padding: 0;
      }

      .search {
        margin-top: -10px !important;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .grid {
      display: grid;
      vertical-align: top;

      .head {
        width: 100%;
        border-right: 2px solid #5cc6d8;
        overflow: hidden;
        vertical-align: middle !important;

        .successMessage {
          font-weight: bold;
          padding-top: 5px;
          text-align: center;
          color: green;
        }

        .errorMessage {
          font-weight: bold;
          padding-top: 5px;
          text-align: center;
          color: red;
          animation: blinker 1s step-start infinite;
        }

        .icon {
          margin: 0 0 0 30px !important;
          display: inline-block;
          width: 90px;
          height: 90px !important;
          fill: gray !important;
        }
      }

      img {
        width: 90%;
        margin: 0 auto;
      }

      .stationList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        grid-row-gap: 40px;

        .oneStation {
          display: grid;
          grid-template-columns: 100px 1fr;
          grid-gap: 15px;

          .info {
            .title {
              font-weight: bold;
              font-size: 1.5em;
              letter-spacing: -1px;
              color: black;
              margin-bottom: 5px;

              a {
                text-decoration: none !important;

                &:hover {
                  text-decoration: underline !important;
                }
              }
            }

            .description {
              font-size: 0.9em;
            }

            .address {
              color: gray;
              font-size: 0.9em;
              padding: 5px 0;
            }

            .activity {
              font-size: 0.8em;
              padding: 5px 0;
              color: gray;
            }

            .tags {
              margin: 5px 0 10px 0;
              font-size: 0.8em;

              .tag {
                display: inline-block;
                padding: 5px 10px;
                margin: 0 5px 5px 0;
                border-radius: 5px;
                background-color: #e3e3e3;
                color: black;
              }
            }
          }

          .icon {
            width: 100%;
            margin-top: 0;
          }
        }
      }

      .stationListShort {
        @extend .stationList;

        grid-template-columns: 1fr !important;
        grid-row-gap: 10px;

        .oneStation {
          display: grid;
          grid-template-columns: 50px 1fr;
          grid-gap: 10px;
          border-top: 1px #efefef solid !important;
          padding-top: 10px;

          &:last-child {
            border: none;
          }
        }

        .activity,
        .tags {
          display: none;
        }

        .icon {
          margin-top: -30px !important;
        }
      }
    }

    .connectors {
      position: relative;
      width: 100% !important;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: baseline;

      li {
        flex: 0 1 auto;
        width: 40px;
        height: auto;
        // border: 1px solid red;

        .parent {
          width: 100%;
          position: relative;

          .svgIcon {
            grid-area: 1 / 1 / 2 / 2;
            width: 40px;
            height: 40px;
            margin-right: 5px;
            vertical-align: top;

            path {
              opacity: 0.2;
              stroke: #9dcb80;
            }
          }
        }
      }
    }
  }
}