// @import "../../assets/scss/variables";
@use '../../assets/scss/variables.scss' as *;

.balance {
  width: 330px !important;
  height: 220px !important;
  border-radius: 15px;
  background-color: beige;
  padding: 20px;
  position: relative;
  margin-top: 20px;
  // box-shadow: 3px 3px 3px rgba($color: hsl(203, 91%, 66%), $alpha: 0.1);
  box-shadow: $boxShadow;

  .id {
    position: absolute;
    bottom: 10px;
    font-size: 0.8em;
    font-family: 'Courier New', Courier, monospace;
  }

  .username {
    font-size: 1.5em;
  }

  .user {
    float: right;
    width: 120px;
    height: 120px;
    margin: -40px -40px 0 0;
    position: relative;
    border-radius: 60%;
    box-shadow: 3px 3px 5px rgba($color: #000000, $alpha: 0.2);

    img {
      width: 100%;
    }
  }

  .amount {
    font-size: 3em;
    color: black;
    letter-spacing: -2px;
    font-weight: bold;
    margin-top: 70px;

    span {
      color: #a4a4a4;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .balance {
    margin-left: 15px !important;
    width: 90% !important;
  }
}
