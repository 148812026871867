// @import '../../../assets/scss/variables.scss';
@use '../../../assets/scss/variables.scss' as *;

.modal {
  background-color: #f8f8eb !important;
  border-radius: 10px;
  box-shadow: $background-input-shadow;
  padding: 15px 30px 30px 30px !important;

  .buttons {
    padding-top: 20px;

    button {
      margin: 0 15px !important;
    }
  }

  button {
    svg {
      width: 28px !important;
      height: 28px !important;
      outline: none !important;
    }
  }
}

.modalOverlay {
  position: absolute !important;
}

.container {
  flex-direction: column;
  flex: 1;
  position: relative;

  .content {
    padding-top: 100px;

    .addButton {
      float: right;
      margin-top: 20px;
      margin-right: 20px;
    }

    .table {
      margin: 0;
      padding: 0;

      li {
        display: grid;
        padding-top: 10px;
        padding-bottom: 5px;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 1fr;
        border-top: 1px solid #dedede;

        div:first-child {
          padding-left: 5px;
        }

        div:last-child {
          margin-top: -5px;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .container {
    .content {
      padding: 60px 15px 15px 15px;
      position: relative;

      .grid {
        display: grid;
        grid-template-columns: 1fr !important;
      }
    }
  }
}
