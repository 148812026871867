// @import '../../assets/scss/variables.scss';
@use '../../assets/scss/variables.scss' as *;

.infoWindow {
  font-size: 11pt !important;
}

.modalContent {
  .link {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  padding: 20px 20px 0 20px;
}

.mapContainer {
  border-bottom: 2px dashed #919191;
}

.savedArea {
  h3 {
    padding-left: 20px;
  }

  .routeList {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;

    li {
      cursor: pointer;
      margin: 0;
      padding: 5px 10px 5px 10px;
      vertical-align: top;

      &:hover {
        background-color: #cbd8f8;
      }

      span {
        display: inline-block;
        padding: 0 0 0 5px;

        &.title {
          span {
            font-size: 0.7em;
            color: #666;
          }
        }

        &.deleteBtn {
          background-color: red;
          color: white;
          padding: 2px 9px 3px 9px!important;
          border-radius: 12px;
          float: right;
        }
      }

      span.icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z'/></svg>");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.modal {
  background-color: #f8f8eb !important;
  border-radius: 10px;
  box-shadow: $background-input-shadow;
  padding: 15px 30px 30px 30px !important;

  .buttons {
    padding-top: 20px;

    button {
      margin: 0 15px !important;
    }
  }

  button {
    svg {
      width: 28px !important;
      height: 28px !important;
      outline: none !important;
      margin: 0 0 15px 15px;
    }
  }
}

.infoWindow {
  font-size: 11pt;
  padding: 10px 15px;

  h2 {
    font-size: 18pt !important;
    margin-top: 0;
    padding-top: 0;
  }
}

.modalOverlay {
  position: absolute !important;
}

.content {
  padding-top: 75px;

  .notAuth {
    .suggestions {
      width: 50%;
      margin: 0 auto;
    }
  }

  .map {
    position: relative;

    .mapContainer {
      position: relative;
      width: 100%;
      height: 400px;
      border-bottom: 2px dashed #ccc;
    }
  }

  .mainSection {
    z-index: 1000;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background: url('/src/client/assets/img/drag.png') no-repeat center top;
    background-position: center 5px;
    background-size: 20px;

    .addChargeStation {
      padding: 30px 20px;
    }

    .premiumFeature {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
      background-color: white;
      padding: 15px;
      margin: 15px 10px 15px 0;
      border-radius: 8px;

      label {
        cursor: not-allowed;
      }

      .noticePremium {
        background-color: coral;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;

        a {
          color: white !important;
        }
      }

      div {
        opacity: 0.5;
      }
    }

    .suggestions {
      padding: 0 25px;
      width: 60%;
      margin: 0 auto;
      border-bottom: 1px dashed #919191;

      h2 {
        margin: 15px 0 10px 0 !important;
        padding: 0px !important;
      }

      ul {
        padding-top: 0;
        margin-top: 0;
      }
    }

    .info {
      display: grid;
      grid-template-columns: 0.4fr 1fr;
      position: relative;

      .points {
        padding: 15px 15px 15px 0;
        border-right: 1px dashed #919191;

        .loadSaveBtn {
          margin-right: 15px;
        }

        .powerLimit {
          button {
            font-size: 0.9em !important;
          }
        }

        input[type="text"] {
          margin-left: -5px;
          width: 300px;
        }

        h4 {
          margin-top: 20px;
          font-size: 16pt;
          letter-spacing: -1px;
        }

        .descSpan {
          display: block;
          padding: 10px 0 0 0;
          margin-left: 80px;
          font-size: 0.9em;
        }

        .chargeDistance {
          width: 100%;

          input {
            width: 100px;
          }

          div.text {
            padding: 10px;
            font-size: 0.9em;
            text-align: left;
          }
        }

        .chargeOptions {
          padding: 10px 20px;
        }

        .column2Layout {
          display: grid;
          grid-template-columns: 0.5fr 1fr;
          grid-gap: 10px;
          vertical-align: middle;
          text-align: center;
        }

        .distanceLimit {
          margin-bottom: 25px;
          width: 100%;

          input {
            width: 100px;
          }

          div.text {
            font-size: 0.9em;
            padding: 10px;
            text-align: left;
          }
        }
      }

      .steps {
        display: grid;
        grid-template-columns: 1fr 0.8fr;

        .column {
          padding: 0 15px 15px 15px;
        }

        .notice {
          padding: 25px;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .modal {
    width: 85% !important;
    font-size: 0.8em;
  }

  .content {
    padding-top: 30px;

    .mainSection {
      font-size: 0.9em;

      .info {
        grid-template-columns: 1fr;

        .points {
          font-size: 0.9em;
          text-align: center;
          padding: 0px;

          .distanceLimit,
          .chargeDistance {
            width: 95%;
          }

          .column2Layout {
            width: 90%;
            grid-template-columns: 1fr 1fr;
          }
        }

        .steps {
          font-size: 0.9em;
          width: 95%;
          padding: 0 15px;
          grid-template-columns: 1fr 1fr;
          .column {
            padding: 0;
          }
        }
      }
    }
  }
}