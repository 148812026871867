@use '../../assets/scss/mixins';
// @import '../../assets/scss/variables';
@use '../../assets/scss/variables.scss' as *;

.fieldWrapper {
  display: block;
  width: 100%;
  padding-bottom: 10px;
}

.content {
  textarea {
    width: 100% !important;
    min-height: 120px;
    padding: 13px 16px;
    margin: 15px 0;
    color: $text-primary-color;
    background: white;
    border-radius: 5px;
    resize: vertical;
    font-size: 1.1em;
    line-height: 1.6;
    box-shadow: $background-input-shadow;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:focus {
      box-shadow: $background-input-active-shadow;
    }

    &.hasError {
      background: $input-error-bg-color;
    }
  }
}