// @import "../../assets/scss/variables";
@use '../../assets/scss/variables.scss' as *;

.svg {
  opacity: 0.5;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.disabled {
  padding-right: 40px;
  color: white;
  cursor: not-allowed;
  background-color: #829fa1 !important;

  &:hover {
    background-color: #698e91 !important;
  }
}

.default {
  padding: 10px 25px;
  margin: 10px 0 10px 0;
  color: $btn-text-color;
  background: $btn-color;
  border-radius: 100px;
  border: none;
  outline: none;
  text-transform: capitalize;
  font-size: 1em;
  font-weight: bold;
  position: relative;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .loader {
    position: absolute;
    right: 12px;
    top: calc(50% - 10px);
  }

  &:hover {
    background-color: $btn-hover-color;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    @extend .disabled;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    transform: scale(0);
    visibility: hidden;
  }

  @keyframes default {
    0% {
      transform: scale(0);
    }

    20% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  &:not(:active)::after {
    animation: default 0.5s ease-out;
  }

  &:focus::after {
    visibility: visible;
  }

  svg {
    @extend .svg;
  }
}

.small {
  @extend .default;

  margin: 5px;
  padding: 10px 25px !important;
  font-size: 0.8em;

  svg {
    @extend .svg;
  }
}

.green {
  background: #55af57 !important;

  &:disabled {
    @extend .disabled;
  }
}

.gray {
  background: #6d6d6d !important;

  &:disabled {
    @extend .disabled;
  }
}

.red {
  background: red !important;

  &:disabled {
    @extend .disabled;
  }
}

.medium {
  @extend .default;

  margin: 5px;
  font-size: 0.9em !important;

  svg {
    @extend .svg;
  }
}

.large {
  @extend .default;

  margin: 10px 0;
  padding: 15px 50px;
  font-size: 1.2em;

  svg {
    @extend .svg;
  }
}
